import { onMounted, onBeforeUnmount } from "vue";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import GC from "@grapecity/spread-sheets";
import { useRouter } from "vue-router";
import useExportExcel from "@/hooks/Reporting/useExportExcel";
import useHeaderTip from "@/hooks/Reporting/useHeaderTip";
import {
  controllingReportVehicle,
  HeaderProps,
  ReportVehicleResponse,
} from "@/API/report";
import { QueryParamsVehicleProps } from "@/views/Reporting/types";
import { ProgramStatusItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";

interface ExtExportColumnsSetting {
  bu: boolean | string;
  year: boolean | string;
  programStatus: boolean | string;
  programSalesStatus: boolean | string;
  programControllingStatus: boolean | string;
}

const headerData = [
  { name: "make", size: 100 },
  { name: "brand" },
  { name: "typeClass" },
  { name: "model", size: 150 },
  { name: "incentiveCategory", size: 150 },
  { name: "programCategory", size: 150 },
  { name: "ytdAvgSi" },
  { name: "ytdAvgSiPercent", month: "ytdMonthList" },
  { name: "q1AvgSi" },
  { name: "q1AvgSiPercent", month: "q1MonthList" },
  { name: "q2AvgSi" },
  { name: "q2AvgSiPercent", month: "q2MonthList" },
  { name: "q3AvgSi" },
  { name: "q3AvgSiPercent", month: "q3MonthList" },
  { name: "q4AvgSi" },
  { name: "q4AvgSiPercent", month: "q4MonthList" },
  { name: "janAvgSi" },
  { name: "janAvgSiPercent", month: "janMonthList" },
  { name: "febAvgSi" },
  { name: "febAvgSiPercent", month: "febMonthList" },
  { name: "marAvgSi" },
  { name: "marAvgSiPercent", month: "marMonthList" },

  { name: "aprAvgSi" },
  { name: "aprAvgSiPercent", month: "aprMonthList" },
  { name: "mayAvgSi" },
  { name: "mayAvgSiPercent", month: "mayMonthList" },
  { name: "junAvgSi" },
  { name: "junAvgSiPercent", month: "junMonthList" },
  { name: "julAvgSi" },
  { name: "julAvgSiPercent", month: "julMonthList" },
  { name: "augAvgSi" },
  { name: "augAvgSiPercent", month: "augMonthList" },
  { name: "sepAvgSi" },
  { name: "sepAvgSiPercent", month: "sepMonthList" },
  { name: "octAvgSi" },
  { name: "octAvgSiPercent", month: "octMonthList" },
  { name: "novAvgSi" },
  { name: "novAvgSiPercent", month: "novMonthList" },
  { name: "decAvgSi" },
  { name: "decAvgSiPercent", month: "decMonthList" },
];
const useSpreadTableSISummaryByVehicle = (
  queryParams: QueryParamsVehicleProps
) => {
  const router = useRouter();
  let workbook: GC.Spread.Sheets.Workbook;

  const getExtExportColumnsSetting = (queryParams: QueryParamsVehicleProps) => {
    const extExportColumnsSetting: ExtExportColumnsSetting = {
      bu: false,
      year: false,
      programStatus: false,
      programSalesStatus: false,
      programControllingStatus: false,
    };

    if (queryParams.bu?.length) {
      extExportColumnsSetting.bu = queryParams.bu.join("/");
    }
    if (queryParams.year !== "") {
      extExportColumnsSetting.year = queryParams.year;
    }
    if (queryParams.programStatus.length) {
      extExportColumnsSetting.programStatus =
        queryParams.programStatus.join("/");
    }
    if (queryParams.programSalesStatus !== "") {
      extExportColumnsSetting.programSalesStatus =
        queryParams.programSalesStatus;
    }
    if (queryParams.programControllingStatus !== "") {
      extExportColumnsSetting.programControllingStatus =
        queryParams.programControllingStatus;
    }

    return extExportColumnsSetting;
  };

  const getExtExportColumnsCount = (
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    let count = 0;
    for (const key in extExportColumnsSetting) {
      if (extExportColumnsSetting[key]) {
        count++;
      }
    }
    return count;
  };

  // 筛选
  const setFilter = (
    sheet: GC.Spread.Sheets.Worksheet,
    index: number,
    len: number
  ) => {
    const range = new GC.Spread.Sheets.Range(-1, index, -1, len);
    const rowFilter = new GC.Spread.Sheets.Filter.HideRowFilter(range);
    sheet.rowFilter(rowFilter);
    rowFilter.filterDialogVisibleInfo({
      sortByColor: false,
    });
  };
  // 头部
  const initHeader = (
    sheet: GC.Spread.Sheets.Worksheet,
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    //挂起
    sheet.suspendPaint();
    const leftColumns = [
      ...(extExportColumnsSetting.bu ? [{ name: "BU" }] : []),
      ...(extExportColumnsSetting.year ? [{ name: "Year" }] : []),
      ...(extExportColumnsSetting.programStatus
        ? [{ name: "Program Status" }]
        : []),
      ...(extExportColumnsSetting.programSalesStatus
        ? [{ name: "Sales Active Status" }]
        : []),
      ...(extExportColumnsSetting.programControllingStatus
        ? [{ name: "Controlling Active Status" }]
        : []),
      { name: "Make" },
      { name: "Brand" },
      { name: "Type Class" },
      { name: "Model" },
      { name: "Incentive Category" },
      { name: "Program Category" },
    ];
    // 两个YTD, 4个Q, 1月到12月
    const firstRight = [
      { name: "YTD" },
      { name: "YTD" },
      { name: "Q1" },
      { name: "Q1" },
      { name: "Q2" },
      { name: "Q2" },
      { name: "Q3" },
      { name: "Q3" },
      { name: "Q4" },
      { name: "Q4" },
      { name: "Jan." },
      { name: "Jan." },
      { name: "Feb." },
      { name: "Feb." },
      { name: "Mar." },
      { name: "Mar." },
      { name: "Apr." },
      { name: "Apr." },
      { name: "May." },
      { name: "May." },
      { name: "Jun." },
      { name: "Jun." },
      { name: "Jul." },
      { name: "Jul." },
      { name: "Aug." },
      { name: "Aug." },
      { name: "Sep." },
      { name: "Sep." },
      { name: "Oct." },
      { name: "Oct." },
      { name: "Nov." },
      { name: "Nov." },
      { name: "Dec." },
      { name: "Dec." },
    ];
    const secondRight = [
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
      { name: "AVG. SI", formal: "Avg.SI = Budget Estimation / Kufri" },
      { name: "% AVG. SI", formal: "AVG.SI% =  Budget Estimation / LLP" },
    ];
    const headerArr: HeaderProps[][] = [
      [...leftColumns, ...firstRight],
      [...leftColumns, ...secondRight],
    ];

    // 设置表头为两行
    sheet.setRowCount(2, GC.Spread.Sheets.SheetArea.colHeader);
    // 需要设置足够的列
    sheet.setColumnCount(
      headerArr[1].length,
      GC.Spread.Sheets.SheetArea.colHeader
    );
    // 设置表头的字体
    const style = new GC.Spread.Sheets.Style();
    style.font = "700 15px Calibri";
    for (let row = 0; row < headerArr.length; row++) {
      for (let col = 0; col < headerArr[row].length; col++) {
        // 给表头单元格赋值
        sheet.setValue(
          row,
          col,
          headerArr[row][col].name,
          GC.Spread.Sheets.SheetArea.colHeader
        );
        // 设置表头单元格的背景色
        sheet
          .getCell(row, col, GC.Spread.Sheets.SheetArea.colHeader)
          .backColor("#bfbfbf");
        // 设置行高
        sheet.setRowHeight(row, 30, GC.Spread.Sheets.SheetArea.colHeader);
        sheet.setStyle(row, col, style, GC.Spread.Sheets.SheetArea.colHeader);
        if (col >= leftColumns.length) {
          sheet.getCell(-1, col).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        }
      }
    }
    // 设置列宽
    sheet.defaults.colWidth = 80;
    const range = new GC.Spread.Sheets.Range(-1, -1, -1, -1);
    sheet.autoMerge(
      range,
      GC.Spread.Sheets.AutoMerge.AutoMergeDirection.rowColumn,
      GC.Spread.Sheets.AutoMerge.AutoMergeMode.free,
      GC.Spread.Sheets.SheetArea.colHeader
    );

    // 冻结，固定前面4列
    sheet.frozenColumnCount(leftColumns.length);
    sheet.setColumnCount(
      headerArr[0].length,
      GC.Spread.Sheets.SheetArea.viewport
    );
    // 筛选
    const extExportColumnsCount = getExtExportColumnsCount(
      extExportColumnsSetting
    );
    setFilter(
      sheet,
      extExportColumnsCount,
      leftColumns.length - extExportColumnsCount
    );

    // 设置headerTip
    useHeaderTip(sheet, headerArr, "#ssHost", 20);
    sheet.resumePaint();
  };
  // Spread初始化设置
  const initSpread = (
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    sheet.suspendPaint();
    const extExportColumnsCount = getExtExportColumnsCount(
      extExportColumnsSetting
    );
    // sheet是否显示
    spread.options.tabNavigationVisible = false;
    spread.options.tabStripVisible = false;

    // 隐藏行头
    sheet.options.rowHeaderVisible = false;
    sheet.defaults.colWidth = 80;

    // 要设置允保护，不允许的才能生效, 不可编辑
    // sheet.options.isProtected = true;
    const style = sheet.getDefaultStyle();
    sheet.defaults.rowHeight = 30;
    style.vAlign = GC.Spread.Sheets.VerticalAlign.center;

    sheet.showRowOutline(false);
    sheet.outlineColumn.options({
      columnIndex: extExportColumnsCount,
      showImage: true,
      showCheckBox: false,
      expandIndicator: require("@/assets/images/increaseIndicator.png"),
      collapseIndicator: require("@/assets/images/decreaseIndicator.png"),
      maxLevel: 2,
    });

    spread.resumePaint();
  };

  const getBindColumnsSetting = (
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    const defaultBindColumnsData = [...headerData] as any[];
    if (extExportColumnsSetting.programControllingStatus) {
      defaultBindColumnsData.unshift({
        name: "programControllingStatus",
        size: 180,
        visible: false,
      });
    }
    if (extExportColumnsSetting.programSalesStatus) {
      defaultBindColumnsData.unshift({
        name: "programSalesStatus",
        size: 150,
        visible: false,
      });
    }
    if (extExportColumnsSetting.programStatus) {
      defaultBindColumnsData.unshift({
        name: "programStatus",
        size: 150,
        visible: false,
      });
    }
    if (extExportColumnsSetting.year) {
      defaultBindColumnsData.unshift({ name: "year", visible: false });
    }
    if (extExportColumnsSetting.bu) {
      defaultBindColumnsData.unshift({ name: "bu", visible: false });
    }

    return defaultBindColumnsData;
  };

  const bindData = (
    sheet: GC.Spread.Sheets.Worksheet,
    data: ReportVehicleResponse[],
    extExportColumnsSetting: ExtExportColumnsSetting
  ) => {
    sheet.suspendPaint();
    const extExportColumnsCount = getExtExportColumnsCount(
      extExportColumnsSetting
    );
    // 设置行数
    sheet.setRowCount(data.length, GC.Spread.Sheets.SheetArea.viewport);
    // 自动生成绑定列
    sheet.autoGenerateColumns = false;
    // 绑定数据源
    for (let row = 0; row < data.length; row++) {
      for (const key in extExportColumnsSetting) {
        if (extExportColumnsSetting[key]) {
          data[row][key] = extExportColumnsSetting[key];
        }
      }

      data[row].level = (
        (data[row].level as string).match(/[-]/g) ?? []
      ).length;
    }

    sheet.setDataSource(data);
    // // 将数据字段绑定到指定列size,formatter,cellType
    const bindColumnsSetting = getBindColumnsSetting(extExportColumnsSetting);
    sheet.bindColumns(bindColumnsSetting);

    const style = new GC.Spread.Sheets.Style();
    style.font = "700 15px Calibri";
    for (let row = 0; row < data.length; row++) {
      // 展开列缩进
      sheet
        .getCell(row, extExportColumnsCount)
        .textIndent(data[row].level as number);

      if (data[row].isSummary) {
        sheet.setStyle(row, -1, style, GC.Spread.Sheets.SheetArea.viewport);
      }
      for (let col = 0; col < bindColumnsSetting.length; col++) {
        // 因为前面有固定的6列，索引是5.所以后面有链接是索引为奇数的，并且，不是汇总行的
        if (
          col >= extExportColumnsCount + 6 &&
          (col - extExportColumnsCount - 7) % 2 === 0 &&
          data[row].bu &&
          sheet.getValue(row, col) !== "-"
        ) {
          // 设置跳转
          sheet.setHyperlink(row, col, {
            url: "",
            command: function (sheet) {
              // 点击了哪一列，就需要把Q/M传过去
              // 新窗口打开 router.resolve
              console.log("data[row]", data[row]);
              const routeUrl = router.resolve({
                path: "/ControllerReporting",
                query: Object.assign({}, data[row] as any, {
                  monthList: (
                    data[row][bindColumnsSetting[col].month as string] as any
                  ).join(","),
                  incentiveCategory: data[row].incentiveCategoryId,
                  programCategory: data[row].programCategoryId,
                }),
              });
              window.open(routeUrl.href, "_blank");
            },
          });

          continue;
        }
      }
    }

    sheet.outlineColumn.refresh();
    for (let row = 0; row < data.length; row++) {
      sheet.rowOutlines.setCollapsed(row, true);
    }
    sheet.resumePaint();
  };

  const init = (
    queryParams: QueryParamsVehicleProps,
    resData: ReportVehicleResponse[]
  ) => {
    workbook?.destroy();

    const spread = new GC.Spread.Sheets.Workbook(
      document.getElementById("ssHost") as HTMLElement
    );
    workbook = spread;
    const sheet = spread.getActiveSheet();

    const extExportColumnsSetting = getExtExportColumnsSetting(queryParams);

    initHeader(sheet, extExportColumnsSetting);
    initSpread(spread, sheet, extExportColumnsSetting);
    bindData(sheet, resData, extExportColumnsSetting);
  };
  const getFetch = (queryParams: any) => {
    const { bu, ...others } = queryParams;
    controllingReportVehicle({ params: { buList: bu, ...others } }).then(
      (res: ReportVehicleResponse[]) => {
        init(queryParams, res);
      }
    );
  };
  const handlerDownload = () => {
    const json = JSON.stringify(
      workbook.toJSON({ includeBindingSource: true })
    );
    const workbookClone = new GC.Spread.Sheets.Workbook();
    workbookClone.fromJSON(JSON.parse(json));
    const sheet = workbookClone.getActiveSheet();
    const columnNum = sheet.getColumnCount();
    for (let col = 0; col < columnNum; col++) {
      sheet.setColumnVisible(col, true);
    }
    useExportExcel(workbookClone, "SI Summary by Vehicle Report");
  };

  onMounted(() => {
    getFetch(queryParams);
  });
  onBeforeUnmount(() => {
    workbook.destroy();
  });
  return {
    handlerDownload,
    getFetch,
  };
};
export default useSpreadTableSISummaryByVehicle;
