
import { defineComponent, ref, reactive, toRefs, computed } from "vue";
import { usetableHeight } from "@/views/System/NSTGroupMSRP/NSTGroupMSRP/utils";
import useYear from "@/hooks/Reporting/useYear";
import useHandleCollapse from "@/hooks/Reporting/useHandleCollapse";
import useBuMakeTP from "@/hooks/Reporting/useBuMakeTP";
import useFindBu from "@/hooks/Reporting/useFindBu";
import useCategory from "@/hooks/Reporting/useCategory";
import moment from "moment";
import useSpreadTableSISummaryByVehicle from "@/hooks/Reporting/views/useSpreadTableSISummaryByVehicle";
import useFetch from "@/hooks/useFetch";
import { getProgramStatus } from "@/API/approve";
import { ProgramStatusItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { QueryParamsProps } from "./types";

const salesStatusOptions = [
  { name: "Active" },
  { name: "Inactive" },
  { name: "All" },
];

export default defineComponent({
  name: "SI Summary By Vehicle",
  setup() {
    const month = ref<string>(moment().startOf("month").format("MM"));
    const { buList, findBu } = useFindBu();

    const programStatus = ref<string[]>([]);
    const programSalesStatus = ref<string>("");
    const programControllingStatus = ref<string>("Active");

    // 下拉的接口用的buid,查询报告用的buName
    const queryParams = reactive({
      bu: [] as string[],
      model: "",
      modelName: "",
      typeClass: "",
      make: ["CBU"],
      brand: "",
    });

    // year相关
    const { year, openYear, handlerOpenChange, handlerPanelChange } = useYear();

    // Program Status
    const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>(
      {},
      [],
      getProgramStatus
    );

    // 展开收起条伯
    const { handleCollapse, isOpen } = useHandleCollapse();

    // 给bu赋默认值
    queryParams.bu = [findBu()];

    // Bu, brand, typeClass, model联动
    const {
      handleFocus,
      makeOptions,
      brandOptions,
      typeClassOptions,
      modelOptions,
      resetObj,
      buNameIdMap,
    } = useBuMakeTP<QueryParamsProps>(queryParams);

    // incentiveCategory, programCategory
    const buNameIdMapList = buNameIdMap();
    const buIds = computed(() => {
      return queryParams.bu.map((b: any) => {
        return buNameIdMapList[b];
      });
    });
    const {
      incentiveCategory,
      programCategory,
      programCategoryOptions,
      incentiveCategoryOptions,
      handleFocusCategory,
      handleChangeCategory,
    } = useCategory(buIds.value);

    // hooks
    const { getFetch, handlerDownload } = useSpreadTableSISummaryByVehicle(
      Object.assign({}, queryParams, {
        year: year.value,
        month: month.value,
        incentiveCategory: incentiveCategory.value,
        programCategory: programCategory.value,
        programStatus: programStatus.value,
        programSalesStatus: programSalesStatus.value,
        programControllingStatus: programControllingStatus.value,
        nstGroupCode: queryParams.model,
      })
    );

    // 渲染表的高度
    const { tableHeight } = usetableHeight("#ssHost");

    // search
    const handlerSearchClick = () => {
      const queryParam = Object.assign({}, queryParams, {
        year: year.value,
        month: month.value,
        incentiveCategory: incentiveCategory.value,
        programCategory: programCategory.value,
        programStatus: programStatus.value,
        programSalesStatus: programSalesStatus.value,
        programControllingStatus: programControllingStatus.value,
        nstGroupCode: queryParams.model,
      });
      getFetch(queryParam);
    };

    // 重置筛选条件
    const handlerReset = () => {
      Object.assign(queryParams, resetObj);
      year.value = moment().add("month", 1).format("YYYY");
      incentiveCategory.value = "";
      programCategory.value = "";
      programStatus.value = [];
      programSalesStatus.value = "";
      programControllingStatus.value = "";
    };

    const changeModel = (key: string, option: { label: string }) => {
      queryParams.modelName = option?.label || "";
    };

    return {
      modelOptions,
      typeClassOptions,
      makeOptions,
      handleFocus,
      ...toRefs(queryParams),
      year,
      openYear,
      handlerOpenChange,
      handlerPanelChange,
      handleCollapse,
      isOpen,
      handlerSearchClick,
      handlerReset,
      incentiveCategory,
      programCategory,
      programCategoryOptions,
      incentiveCategoryOptions,
      tableHeight,
      handlerDownload,
      month,
      handleFocusCategory,
      handleChangeCategory,
      buList,
      brandOptions,
      programStatus,
      programSalesStatus,
      programControllingStatus,
      programStatusOptions,
      salesStatusOptions,
      changeModel,
    };
  },
});
